/* Search bar */
.search {
  display: flex;
  align-items: center;
}

.search input[type="text"] {
  height: 30px;
  padding: 0 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.search img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}

/* Login and signup button */
.login_signup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}

.login_signup img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.login_signup span {
  font-size: 16px;
}

/* Cart container */
.cart_container {
  display: flex;
  align-items: center;
}

/* Cart icon and bubble */
.cart {
  position: relative;
  cursor: pointer;
  margin-left: 20px;
}

.cart img {
  width: 30px;
  height: 30px;
}

/* Cart bubble */
.buble {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
}
