/* LogoTransition.css */

.logo-transition-container h2 {
    /* font-weight: bold; */
    color: blue; /* Blue color */
    font-size: 24px; /* Adjust font size as needed */
    margin-bottom: 15px;
    /* text-align: center;  */
    text-transform: uppercase; /* Convert text to uppercase */
    position: relative; /* For the pseudo-element styling */
    /* border: 1px solid blue; */
    
  }
  
.logo-transition-container h2::before {
    content: ''; /* Create a pseudo-element */
    position: absolute;
    bottom: -10px; /* Adjust positioning as needed */
    left: 2%;
    transform: translateX(-50%);
    width: 50px;
    height: 2px;
    padding: 4px;
    background-color: blue; /* Match the heading color */
  }
.our-client{
    margin-top: 8px;
    color: red;
}
.logo-transition-container {
    overflow: hidden;
    padding: 30px; /* Add padding as needed */
  }
  
  .logo-transition-wrapper {
    display: flex;
    animation: slide 20s linear infinite;
  }
  
  @keyframes slide {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .clients {
    animation: slideRightToLeft 20s linear infinite;
  }
  
  @keyframes slideRightToLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  .transition-logo {
    width: 120px;
    height: auto;
    margin: 0 10px;
    border: 2px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out ;
  }
  
  /* Additional styling for Bootstrap classes */
  .logo-transition-container {
    background-color: #f8f9fa; /* Set a background color */
  }
  
  .logo-transition-wrapper {
    align-items: center; /* Center logos vertically */
  }
  
  .transition-logo:hover {
    transition: transform 0.3s ease-in-out ;
    transform: scale(1.15); /* Enlarge logo on hover */
  }
  