.container {
  width: 180px;
  padding: 20px;
  margin: 2em 0em 0em 2em;
  border-color: blue;
  border-style: solid;
  height: 700px;
}
.container h3{
  margin-top:1em;
}
.container button{
  margin-top:2em;
  border: none;
  padding: 10px;
  margin: 2px;
}
.container button .resetfilter{
  background-color: red;
}
.container div {
  cursor: pointer;
}

.container .ratingStar {
  font-weight: 400;
}

.container .ratingStarSelected {
  border: 1px solid black;
  font-weight: bold;
}

@media (max-width: 767px) {
  .container {
    display: none; /* Hide the entire container on mobile */
  }
}
