.container {
  width: 40%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
}

.container .smallImg {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px;
}

.container .smallImg img {
  height: 80px;
  width: 100px;
  margin: 8px;
  object-fit: contain;
}

.container .bigImg {
  padding: 10px;
}

.container .bigImg img {
  height: 95%;
  width: 300px;
  object-fit: contain;
}
.smallImg img {
 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Example shadow style */
 
}
.bigImg img {

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Example shadow style */
 
}
