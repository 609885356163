/* Button styling to stay at the bottom-right */
.chatbot-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    cursor: pointer;
}

/* The Modal (background) */
.modals {
    display: block;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content */
.modals-content {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: white;
    width: 300px;
    height: 500px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Close button styling */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
