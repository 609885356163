.form-container {
    padding-top: 40px;
    width: 50% !important;
    margin-top: 2.5em;
    margin-bottom: 3em;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
.login-button {
  width: 100%;
}

.form-container:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
.form-column {
    margin: 20px;
  }

@media (max-width: 576px) {
  .form-container {
    width: 70% !important;
    padding: 2em;
    max-width: 600px;
  }
  /* .form-column {
    margin: 20px;
  } */
}