.container {
  background-color: white;
  width: 96%;
  margin: 10px;
  padding: 10px;
  color: black;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease-in-out;
}

.container:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
  transform: translateY(-5px);
}

