.container {
  background-color: rgb(239, 244, 255);
  margin-right: 10px;
  margin-top: 15px;
  /* margin-bottom: 10em; */
  padding: 10px;
  width: 100%;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease-in-out;
}
.container:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
  transform: translateY(-5px);
}

.container h5{
  height: 70px;
}

.container img {
  width: 130px;
  height: 100px;
}
