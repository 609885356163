.container{
    background-color: beige;
    margin-right: 40px;
    padding: 10px;
    width: 150px;
    text-align: center;
    img{
        width: 130px;
        height: 100px;
    }
}