.body{
   table{
       font-family: Arial, Helvetica, sans-serif;
       border-collapse: collapse;
       width: 100%;
   }

   td, th {
       border: 1px solid #dddddd;
       text-align: left;
       padding: 8px;
   }

   tr:nth-child(even){
       background-color: #dddddd;
   }

   .addbutton{
       margin: 10px;
   }

   .actionButton{
       display: flex;
       justify-content: space-around;
       :hover{
           transform: scale(1.2);
       }
       img{
           height: 20px;
           width: 20px;
           cursor: pointer;
       }
   }
}