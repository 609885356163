.about-section {
    background-color: #f8f9fa; 
    padding: 40px 60px; 
    border-radius: 0; 
    width: 100%; 
    margin: 0; 
  }
  .about-section h1 {
    color: #0d6efd;
  }

.about-page {
    color: #333;
  }
  
  .about-page p,
  .about-page ul {
    color: #555;
    line-height: 1.6;
  }

  .custom-hr {
    margin-bottom: 2.5em;
    color: #555;
}

  
  .aboutuscard {
    background-color: #f8f9fa;
    border: 0;
    box-shadow: 50px 65px 70px rgba(0, 0, 0, 0.2); 
    transition: transform 0.9s ease, box-shadow 0.9s ease; 
  }
  .aboutuscard:hover {
    transform: translateY(-5px);  
  }
  
  .aboutuscard ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .aboutuscard ul li::before {
    content: "✔️";
    margin-right: 8px;
    color: #0d6efd;
  }
  
  button.shadow-sm {
    padding: 10px 20px;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    .about-page h1 {
      font-size: 1.5rem;
    }
  
    .aboutuscard {
      margin-bottom: 1rem;
    }
  }
  